export const SET_ALERT = 'SET_ALERT';
export const REMOVE_ALERT = 'REMOVE_ALERT';
export const REGISTER_SUCCESS = 'REGISTER_SUCCESS';
export const REGISTER_FAIL = 'REGISTER_FAIL';
export const LOGIN_SUCCESS = 'LOGIN_SUCCESS';
export const LOGIN_FAIL = 'LOGIN_FAIL';
export const GET_ADMIN_DATA = 'GET_ADMIN_DATA';
export const CREATE_EXERCISE = 'CREATE_EXERCISE';
export const UPDATE_EXERCISE = 'UPDATE_EXERCISE';
export const GET_EXERCISES = 'GET_EXERCISES';
export const USER_LOADED = 'USER_LOADED';
export const AUTH_ERROR = 'AUTH_ERROR';
export const GET_CATEGORIES = 'GET_CATEGORIES';
export const CREATE_CATEGORY = 'CREATE_CATEGORY';
export const GET_WORKOUTS = 'GET_WORKOUTS';
export const GET_WORKOUT_HISTORY = 'GET_WORKOUT_HISTORY';
export const CREATE_WORKOUT = 'CREATE_WORKOUT';
export const START_WORKOUT = 'START_WORKOUT';
export const UPDATE_WORKOUT_PROGRESS = 'UPDATE_WORKOUT_PROGRESS';
export const FINISH_WORKOUT = 'FINISH_WORKOUT';
